.contactbox {
  @apply absolute
  bottom-0
  mb-8
  px-4;
}

.title {
  @apply mt-4;
}

.email {
  @apply flex
    mt-4;
}
.link {
  text-decoration: none;
  @apply text-gray-800;
}
.links {
  @apply flex
    mt-4;
}

.inline {
  @apply flex;
}

.gray {
  @apply text-gray-800;
}
