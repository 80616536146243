@mixin primary_button {
  --bs-btn-bg: #2293fb !important;
  --bs-btn-border-color: #2293fb !important;
}

@mixin outline_button {
  --bs-btn-bg: transparent !important;
  --bs-btn-color: #2293fb !important;
  --bs-btn-border-color: #2293fb !important;
  --bs-btn-hover-bg: #2293fb !important;
  --bs-btn-hover-color: #fff !important;
}
