.icon {
  height: 25px;
  width: 25px;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
}
.popover {
  background-color: #79848f;
  border-color: rgba($color: #fff, $alpha: 0.15);
}
.popover > div:first-child {
  display: none;
}
