//@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu";


@import "~bootstrap/scss/bootstrap";

html, body, #root {
  padding: 0;
  width: 100%;
  height: 100%;
  background: #ebedf3;
}

.main-wrapper-page {
  width: 100%;
  height: 100vh;
  background: #f3f4f6;
  position: relative;
  display: block;
  
}

.main-wrapper {
  width: 100%;
  height: 100%;
  background: #f3f4f6;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  box-shadow: inset 2px 1px 9px 1px  rgba($dark, 0.10);
  overflow: auto;
  padding-top: 1rem;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}