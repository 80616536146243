$border-radius: 1.5rem;

$primary: #2293FB;

.btn-primary {
    color: #ffffff !important;
    font-weight: bold !important;
}

.gvisible {
    opacity: 1;
    transition: opacity 2s ease-out;}
  
.ghidden {
    opacity: 0;
}

@import 'node_modules/bootstrap/scss/bootstrap.scss';

