@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@import "../../../styles/mixin.scss";
@import "../../../styles/variable.scss";

.container {
  @apply content-center self-center mt-20;
}

.header {
  font-size: 72px;
  color: $base-color-text;
  @apply text-center font-bold mb-10 relative z-50;
}

.button {
  height: 60px;
  width: 236px !important;
  line-height: 28px !important;
  padding: 16px 0 !important;
  border-radius: 18px !important;
  font-size: 20px !important;
}

.contact_sale_button {
  @include primary_button;
}
.settle_button {
  margin-left: 15px;
  @include outline_button;
}

@include media-breakpoint-down(lg) {
  .header {
    @apply text-6xl mb-10;
  }
  .button {
    height: 52px;
    width: 200px !important;
    padding: 10px 0 14px !important;
    font-size: 20px !important;
    border-radius: 16px !important;
  }
}

@include media-breakpoint-down(md) {
  .header {
    @apply text-5xl mb-10;
  }
  .button {
    height: 46px;
    width: 180px !important;
    padding: 7px 0 11px !important;
    font-size: 20px !important;
    border-radius: 14px !important;
  }
}
@include media-breakpoint-down(sm) {
  .header {
    @apply text-3xl mb-6;
  }
  .button {
    height: 40px;
    width: 130px !important;
    padding: 4px 0 8px !important;
    font-size: 14px !important;
    border-radius: 12px !important;
  }
}
