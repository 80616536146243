.table {
  padding: 0 !important;
  > div > div {
    > div:first-child > div {
      background-color: lightgray;
    }
  }
}
.kycContent {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d3d3;
  overflow-x: hidden;
}
.kycField {
  display: flex;
  border-bottom: 1px solid lightgrey;
  .label {
    background-color: rgb(234, 234, 234);
    border-left: 1px solid lightgrey;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .value {
    border-right: 1px solid lightgrey;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
