@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.mainheader {
  grid-area: header;
  height: 84px;
  z-index: 50;
  border-color: #c7cbdb !important;
  @apply p-10
    pt-6
    pb-6
    border-0
    border-b
    border-solid;
}

.logo {
  @apply h-8;
}

.navlink {
  font-size: 19px;
  color: #797d86;
  @apply ml-6
    font-normal
    text-base;
}

.active {
  color: #2293fb;
}
.active:hover {
  color: #2293fb;
}
.active:focus {
  color: #2293fb;
}
.active:visited {
  color: #2293fb;
}

.navbutton {
  font-size: 19px;
  @apply text-gray-800
    text-base
    font-semibold
    pt-2
    ml-6
    rounded-2xl;
}

.collapse {
  display: flex;
  justify-content: flex-end;
  @apply bg-transparent;
}

@include media-breakpoint-down(md) {
  .mainheader {
    @apply p-2;
  }

  .offcanvas {
    width: 200px !important;
  }

  .nav {
    background-color: white;
    @apply rounded-xl
      text-left;
  }

  .navlink,
  .navbutton {
    margin-bottom: 10px;
    @apply text-xl
      ml-0;
  }
}
