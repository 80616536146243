@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.footer {
  z-index: 50 !important;
  @apply relative
  bottom-0
  w-full
  m-0;
}

.footer_container {
  min-height: 189px;
  line-height: 200%;
  @apply bg-gray-800
  text-white
  px-10
  py-8;
}

.leftblock {
}
/*
.footer {
  width: 100%;
  @apply flex-shrink-0
    h-[189px] 
    bg-gray-800
    relative;
}
*/
/*
    left-0 
    bottom-0
    absolute 
*/
.logo {
  @apply h-8
}

.description {
  opacity: 0.3 !important;
  @apply text-base 
  font-normal 
  leading-relaxed;
}

.copyright {
  opacity: 0.3 !important;
  @apply text-sm 
  font-normal 
  leading-snug;
}

.contacts {
  opacity: 0.3;
  line-height: 1.5rem !important;
  @apply text-base
  font-normal;
}

.title {
  @apply left-0 top-0 absolute text-white text-opacity-30 text-base font-normal leading-relaxed;
}

.email {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 15px !important;
  padding-left: 0 !important;
  @apply font-normal;
}

.email:hover {
  color: #ffffff !important;
}

.contacts_container {
  margin-left: auto !important;
  margin-right: 0 !important;
  width: 235px !important;
}

@include media-breakpoint-down(md) {
  .footer_container {
    @apply bg-gray-800
    text-white
    p-6;
  }
  .contacts_container {
    margin-left: 0 !important;
    padding-top: 12px !important;
    padding-left: 0 !important;
    .row .col {
      justify-content: flex-start !important;
    }
  }
}
