.item {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  &:hover {
    background-color: #eaeaea;
  }
  &:last-child {
    border-bottom: 0;
  }
}

.label {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #898989;
  text-transform: capitalize;
}
.value {
  padding-right: 10px;
  font-weight: 500;
}
