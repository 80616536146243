.mainheader {
  grid-area: header;
  padding: 0.5rem;
  height: 56px;
  border-bottom: 1px solid #C6CBDD;
  @apply pr-8
}

.container {
}

.currency-balance {
  margin-left: auto;
}