.frame {
  max-width: 512px;
  @apply bg-white
    rounded-xl
    text-left
    p-8
    mt-10
}
// was 464
//Form w-[464px] h-[550px] relative
