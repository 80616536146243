@import "../../../styles/mixin.scss";
@import "../../../styles/variable.scss";

.h1 {
  font-size: 28px;
  line-height: 33.6px;
  color: $base-color-text;
  @apply font-bold text-center;
}

.h2 {
  color: $base-color-text;
  @apply text-lg
    font-normal
    text-center;
}

.divider {
  height: 29px;
}

.input_field {
  margin-bottom: 20px !important;
  input,
  textarea {
    border-radius: 15px;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  label {
    color: #a9a9a9;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

.button {
  @include primary_button;
  height: 52px !important;
  border-radius: 15px !important;
}
