.h1 {
  @apply text-black
    text-2xl
    font-semibold;
}

.h2 {
  @apply text-gray-800
    text-lg
    font-normal;
}

.header {
  font-size: 28px;
  line-height: 33.6px;
  @apply text-gray-800
    font-bold;
}
