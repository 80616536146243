.container {
  background-color: white;
  margin: 0;
  position: relative;
  padding: 10px;
  height: 100%;
}

.title {
  font-size: 32px;
  color: #172230;
  margin-bottom: 30px;
}
