.copyButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.privateKey {
  display: flex;
  flex-direction: column;
  flex: 1;
}