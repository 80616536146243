.header {
    font-size: 28px;
    line-height: 33.6px;
    @apply text-gray-800
      font-bold
      text-center;
  }

.subheader {
    @apply
    text-center
    mb-10
}

.numbers
{    @apply
    mt-10
    pl-9

}

