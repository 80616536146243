$border-radius: 1.5rem;
@import "node_modules/bootstrap/scss/bootstrap.scss";

$body-font-family: Roboto, system-ui, -apple-system, "Segoe UI",
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

body {
  font-family: $body-font-family;
}

a.active.sidebar-menu-nav-link > span.sidebar-menu-nav-icon > img {
  filter: invert(1);
}
