@import "../../../styles/mixin.scss";
@import "../../../styles/variable.scss";

.h1 {
  color: $base-color-text;
  @apply text-2xl
    font-semibold;
}

.h2 {
  color: $base-color-text;
  @apply text-lg
    font-normal;
}

.modal_container {
  >div:first-child {
    max-width: 420px !important;
    margin-top: 62px !important;
  }
}

.modal_header {
  position: relative;
  border-bottom: 0 !important;
  padding: 32px 24px 0 !important;

  button {
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.modal_footer {
  border: 0 !important;
  padding: 16px 32px 40px !important;
}

.button_ok {
  width: 100% !important;
  margin: 0 !important;
  @include primary_button;
}
