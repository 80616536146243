.rounded {
  @apply rounded-3xl
    bg-slate-500
    p-2
    m-2
    mx-0
    text-center
    text-white
    text-xs
    font-medium;
}
