.container {
  min-height: 100%;
  height: max-content !important;
}

.formItem {
  border-radius: 15px !important;
}
.label {
  color: #a9a9a9;
}

.toggleButton {
  height: 35px;
  border-radius: 35px !important;
  width: 50%;
  font-size: 14px;
  font-weight: 400 !important;
  color: #a9a9a9 !important;
  background-color: white !important;
  border: 0 !important;
}

.toggleButtonActive {
  height: 35px;
  border-radius: 35px;
  width: 50%;
  font-size: 14px;
  color: white !important;
  background-color: #2293fb !important;
}

.radioButton {
  border-radius: 15px !important;
  text-align: left !important;
  font-size: 16px;
  color: #767676 !important;
  background-color: white !important;
  border-color: #d2d2d2 !important;

  height: 58px;
  box-sizing: border-box;
}

.radioButtonActive {
  color: #172230 !important;
  border-color: #2293fb !important;
  background-color: rgba(34, 147, 251, 0.05);
}

.batchLabel {
  position: absolute;
  top: -15px;
  left: 5px;
  color: #7d8a9b;
  background-color: white;
  z-index: 2;
  padding: 2px 7px;
}
.uploadFile {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div:first-child {
    flex-grow: unset !important;
  }
}

.uploadFile > div:first-child {
  flex-grow: unset !important;
}
